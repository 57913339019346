import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CartType } from "../utils/types";
import { getUserCart } from "../../../api/fakeStoreCalls";

type CartState = {
  cart: CartType;
  notLoggedInCart: CartType;
  hasValidUser: boolean;
};

const initialState: CartState = {
  cart: {
    id: -1,
    userId: -1,
    date: "",
    products: [],
    __v: 0,
  },
  notLoggedInCart: {
    id: -1,
    userId: -1,
    date: "",
    products: [],
    __v: 0,
  },
  hasValidUser: false,
};

export const fetchUserCart = createAsyncThunk(
  "cart/fetchUserCart",
  async (user: number) => {
    try {
      let response = await getUserCart(user);
      if (response === null) {
        response = {
          id: -1,
          userId: user,
          date: "",
          products: [],
          __v: 0,
        };
      }
      return response as CartType;
    } catch {}
  }
);

function addItemTo(
  cart: CartType,
  action: PayloadAction<{ id: number; quantity: number }>
) {
  const itemIndex = cart.products.findIndex(
    (item) => item.productId === action.payload.id
  );
  if (itemIndex >= 0) {
    cart.products[itemIndex].quantity += action.payload.quantity;
  } else {
    cart.products.push({
      productId: action.payload.id,
      quantity: action.payload.quantity,
    });
  }
}

function removeItemFrom(
  cart: CartType,
  action: PayloadAction<{ id: number; quantity: number }>
) {
  const itemIndex = cart.products.findIndex(
    (item) => item.productId === action.payload.id
  );
  if (cart.products[itemIndex].quantity === 1) {
    cart.products = cart.products.filter(
      (item) => item.productId !== action.payload.id
    );
  } else {
    cart.products[itemIndex].quantity -= action.payload.quantity;
  }
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCart: (
      state,
      action: PayloadAction<{ id: number; quantity: number }>
    ) => {
      if (state.hasValidUser) {
        addItemTo(state.cart, action);
      } else {
        addItemTo(state.notLoggedInCart, action);
      }
    },

    removeCart: (
      state,
      action: PayloadAction<{ id: number; quantity: number }>
    ) => {
      if (state.hasValidUser) {
        removeItemFrom(state.cart, action);
      } else {
        removeItemFrom(state.notLoggedInCart, action);
      }
    },

    clearCart: (state) => {
      state.cart.products = [];
      state.notLoggedInCart.products = [];
    },

    resetCart: (state) => {
      state.cart = initialState.cart;
      state.notLoggedInCart = initialState.cart;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUserCart.fulfilled, (state, action) => {
      state.cart = action.payload!;
      if (state.notLoggedInCart.products.length > 0) {
        let payloadItems = action.payload!.products;
        let unregisteredCart = state.notLoggedInCart.products;
        if (payloadItems.length > 0) {
          for (let i = 0; i < unregisteredCart.length; i++) {
            let itemIndex = payloadItems.findIndex(
              (itm) => itm.productId === unregisteredCart[i].productId
            );

            if (itemIndex > 0) {
              payloadItems[itemIndex].quantity += unregisteredCart[i].quantity;
            } else {
              payloadItems.push(unregisteredCart[i]);
            }
          }
        } else {
          state.cart.products = state.notLoggedInCart.products;
        }
      } else {
      }

      state.cart.id = action.payload!.id;
      state.cart.userId = action.payload!.userId;
      state.hasValidUser = true;

      if (state.notLoggedInCart.products.length !== 0) {
        let products = state.notLoggedInCart.products;
        const payloadItems = action.payload!.products;
        if (payloadItems.length !== 0) {
          for (let i = 0; i < payloadItems.length; i++) {
            const itemIndex = products.findIndex(
              (itm) => itm.productId === payloadItems[i].productId
            );
            if (itemIndex >= 0) {
              products[itemIndex].quantity += payloadItems[i].quantity;
            } else {
              products.push(payloadItems[i]);
            }
          }
        }
      } else {
        state.cart.products = action.payload!.products;
      }
    });
  },
});

export const { addCart, removeCart, resetCart } = cartSlice.actions;
