import { Outlet } from "react-router";
import { Navigation } from "./Navigation";
import { useEffect } from "react";
import { useStoreDispatch } from "./store/hooks";
import { fetchUsers, setActiveUser } from "./store/user-slice";
import { fetchCategories, fetchItems } from "./store/item-slice";
import { useAuthContext } from "./auth-context";
import { UserType } from "./utils/types";
import { fetchUserCart } from "./store/cart-slice";

export function FakeStore() {
  const authCtx = useAuthContext();

  const userDispatch = useStoreDispatch();

  useEffect(() => {
    async function loadUsers() {
      try {
        const response = (await userDispatch(fetchUsers()))
          .payload as UserType[];
        if (authCtx.user) {
          const user = response.find((user) => user.username === authCtx.user)!;
          userDispatch(
            setActiveUser({
              user: user,
            })
          );
          userDispatch(fetchUserCart(user.id));
        }
      } catch {}
    }
    loadUsers();
    userDispatch(fetchItems());
    userDispatch(fetchCategories());
  }, []);

  return (
    <div>
      <Navigation />
      <Outlet />
    </div>
  );
}
