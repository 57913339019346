import { MouseEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "./auth-context";
import { useStoreDispatch, useStoreSelector } from "./store/hooks";
import { makeFirstLetterUpperCase } from "./utils/functions";
import { resetCart } from "./store/cart-slice";

export function Navigation() {
  const authCtx = useAuthContext();
  const user = useStoreSelector((state) => state.user.currentUser);
  const cartDispatch = useStoreDispatch();

  const navigate = useNavigate();

  function logoutHandler(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    authCtx.logout();
    cartDispatch(resetCart());
    navigate("./login");
  }

  return (
    <nav className="navbar navbar-expand-sm bg-body-tertiary ps-lg-5 pe-lg-5">
      <div className="container-fluid">
        <h1
          className="navbar-brand p-2 m-0"
          onClick={() => {
            navigate("./");
          }}
        >
          Fake Store
        </h1>
        <div>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#search"
            aria-controls="search"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-search"></i>
          </button>

          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="search"
        >
          <form className="d-flex align-self-center" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form>
        </div>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item ">
              <Link
                className="btn btn-outline-secondary"
                to={`cart/${user?.id}`}
              >
                Cart
              </Link>
            </li>
            {authCtx.user !== "" && (
              <li className="nav-item ">
                <Link className="nav-link" to={`user/${user?.id}`}>
                  {user && makeFirstLetterUpperCase(user.name.firstname)}
                </Link>
              </li>
            )}
            {authCtx.user === "" && (
              <li className="nav-item">
                <Link className="nav-link" to={"login"}>
                  Login
                </Link>
              </li>
            )}
            {authCtx.user !== "" && (
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"./login"}
                  onClick={logoutHandler}
                >
                  Log out
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
