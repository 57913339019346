import { CartItem } from "./components/CartItem";
import { useStoreSelector } from "./store/hooks";

export function Checkout() {
  const cartSelector = useStoreSelector((state) => {
    if (state.cart.hasValidUser) {
      return state.cart.cart;
    } else {
      return state.cart.notLoggedInCart;
    }
  });
  const itemsSelector = useStoreSelector((state) => state.item.itemsOrigin);
  let total = 0;
  return (
    <div className="container col-12 col-md-8 gap-3 ">
      {cartSelector &&
        cartSelector.products.map((item) => {
          const cartItem = itemsSelector.find(
            (storeItem) => storeItem.id === item.productId
          );
          total += cartItem!.price * item.quantity;
          return (
            <CartItem
              key={item.productId}
              id={item.productId}
              image={cartItem!.image}
              title={cartItem!.title}
              amount={item.quantity}
              price={cartItem!.price}
            />
          );
        })}
      <h3> Total: ${total.toFixed(2)}</h3>{" "}
      <button className="btn btn-outline-success ">Pay now</button>
    </div>
  );
}
